import React from 'react'
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import {Landing} from "./pages/Landing/Landing"


function Routes() {
    return (
            <Switch>
                <Route path="/" exact component={Landing}/>
                
            </Switch>
    )
}

export default Routes