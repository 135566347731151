import React, { useState, useEffect } from 'react';
import { Collapse, Slider, Select, Tabs, Drawer } from 'antd';
import { CalculatorOutlined, MenuOutlined } from '@ant-design/icons'
import { Link, DirectLink, Element, Events, animateScroll as scroll, scrollSpy, scroller } from 'react-scroll'
import down_arrow_icon from '../../assets/images/icons/down-arrow.svg'
import owl_icon from '../../assets/images/icons/owl.png'
import ai_icon2 from '../../assets/images/icons/Ai_icon2.png'
import ai_icon from '../../assets/images/icons/Ai_icon.png'
import lion_icon from '../../assets/images/icons/lion.png'
import tick_icon from '../../assets/images/icons/tick_icon.png'
import fred from '../../assets/images/fred.png'
import alim from '../../assets/images/alim.png'
import chris from '../../assets/images/chris.png'
import samone from '../../assets/images/simone.png'
import ratshefola from '../../assets/images/ratshefola.jpeg'
import ALA_logo from '../../assets/images/ALA-Logo.png'
import UJ_logo from '../../assets/images/UJ-logo.png'
import Microsoft_logo from '../../assets/images/Microsoft-logo.png'
import Nova_logo from '../../assets/images/Nova-Logo.png'
import graph_desktop from '../../assets/images/graph_desktop.png'
import graph_one from '../../assets/images/Graph_1.png'
import graph_two from '../../assets/images/Graph_2.png'
import pillars_icon from '../../assets/images/pillars.png'
import pillars_icon_mobile from '../../assets/images/pillar-mobile.png'
import new_way from '../../assets/images/New_ways.png'
import old_way from '../../assets/images/Old_ways_graph.png'
import old_way_mobile from '../../assets/images/Old_ways_mobile.png'
import new_way_mobile from '../../assets/images/New_ways_mobile.png'
import full_logo from '../../assets/images/full-logo.png'
import logo_icon from '../../assets/images/icons/logo-icon.png'


import './landing.css'

const mql = window.matchMedia(`(max-width: 768px)`);
const mql_600 = window.matchMedia(`(max-width: 600px)`);


export function Landing(props) {

    const { Option } = Select;
    const { Panel } = Collapse;

    const [activeItem, setActiveItem] = useState(1)
    const [currentMenu, setCurrentMenu] = useState(0)
    const [model, setModel] = useState("")
    const [institution, setInstitution] = useState("")
    const [numOfStudents, setNumOfStudents] = useState("")
    const [revenue, setRevenue] = useState(0)
    const [period, setPeriod] = useState(0)
    const [performance, setPerformance] = useState(0)
    const [monthlyFees, setMonthlyFees] = useState(0)
    const [isTablet, setIsTablet] = useState(mql.matches)
    const [isMobile, setIsMobile] = useState(mql_600.matches)
    const [drawerVisible, setIsDrawerVisible] = useState(false)

    const mediaQueryChanged = () => {
        setIsTablet(mql.matches)
        setIsMobile(mql_600.matches)
        // setSidebarOpen(false)
    }

    mql.addEventListener("change", mediaQueryChanged)

    useEffect(() => {
        console.log("landing")
        Events.scrollEvent.register('begin', function () {
            console.log("begin", arguments);
        });

        Events.scrollEvent.register('end', function () {
            console.log("end", arguments);
        });
    }, []);

    const scrollToTop = () => {
        scroll.scrollToTop();
    }

    const scrollTo = () => {
        scroller.scrollTo('scroll-to-element', {
            duration: 800,
            delay: 0,
            smooth: 'easeInOutQuart'
        })
    }

    const setSelectedMenu = (menuNumber, visibility) => {
        if (visibility) {
            setCurrentMenu(menuNumber)
        }

    }

    const { TabPane } = Tabs;

    function callback(key) {
        console.log(key);
    }

    const toggleSelectedItem = (num) => {
        setActiveItem(num)
    }

    const handleModelChange = () => {

    }

    const onDrawerClose = () => {
        setIsDrawerVisible(false)
    };

    const calculateRevenue = () => {
        let revenue = 0;
        if (model === "hybrid") {
            revenue = 3 * (numOfStudents) * 0.75 * (monthlyFees) * period * performance
        }
        else if (model === "automated") {
            revenue = ((8 * (numOfStudents) * 0.5 * (monthlyFees)) * period) * performance
        }
        return revenue
    }

    const getRevenue = () => {
        if ((numOfStudents > 0 && period > 0 && monthlyFees > 0 && performance > -1 && model !== null)) {
            setRevenue(calculateRevenue())
        }
        else {
            setRevenue(0)
        }
    }

    const reset = () => {
        setRevenue(0)
        setPeriod(0)
        setMonthlyFees(0)
        setNumOfStudents(0)
        setPerformance(0)
    }

    useEffect(() => {
        getRevenue()
    }, [model])

    useEffect(() => {
        getRevenue()
    }, [numOfStudents])

    useEffect(() => {
        getRevenue()
    }, [monthlyFees])

    useEffect(() => {
        getRevenue()
    }, [period])

    useEffect(() => {
        getRevenue()
    }, [performance])

    const FounderTabs = () => (
        <Tabs name="founders" className='founder-tabs' defaultActiveKey="1" onChange={callback}>
            <TabPane tab={<div className='tab-head'> <span className="tab-head-name">Kola Olajide</span> <div className="diamond"></div></div>} key="1">
                <div className="founder-section-flex">
                    <div className="founder-info-container">
                        <div className="founder-info-text">
                            <div style={{ width: "100%" }} className={"partner-section-header"} >Kola Olajide </div>
                            <p style={{ marginBottom: "30px" }}>
                                Kola co-founded Digiclass out of his passion for building high-quality tech products
                                designed to enable forward-thinking organizations to compete and perform in the 21st
                                century. He has extensive experience as the technical lead for several major government
                                and private sector systems design projects including leading the technology development
                                for learning systems at the African Leadership University (recently voted most innovative
                                company in Africa by Fast Company) where he successfully delivered a world-class
                                learning management system built around project based learning and skills tracking and
                                more recently led the digital transformation of SAʼs largest privately owned car dealership
                                with $75m annual revenue.
                            </p>

                            <p>
                                He is a Microsoft technology partner and has received multiple innovation awards—most
                                notably a R500,000 innovation prize at the annual SAB innovation awards, best innovation
                                in education meeting UN Sustainable development goals (out of over 1,000 applications
                                submitted globally), Anzisha/Mastercard foundation award as one of the top entrepreneurs
                                in Africa and a recent Forbes 30-Under-30 Honoree for his work in education, marketing,
                                insurance and banking. He has been invited to speak on multiple media platforms most
                                notably CNN, and CNBC. He loves to lecture in his free time and was recently speaking
                                about Africa focused business models and talent acquisition strategy at the Stanford
                                Graduate School Of Business and MIT.
                            </p>
                        </div>
                    </div>
                    <div className="founder-section-right-1"></div>
                </div>
            </TabPane>

            <TabPane tab={<div className='tab-head'> <span className="tab-head-name">S'onqoba Vuba</span> <div className="diamond"></div></div>} key="2">
                <div className="founder-section-flex">
                    <div className="founder-info-container">
                        <div className="founder-info-text">
                            <div style={{ width: "100%" }} className={"partner-section-header"} >S'onqoba Vuba</div>
                            <p>
                                She is a proud graduate of the University of the Witwatersrand, Johannesburg (Wits);
                                holding a BSc in Mathematical Sciences anda BSc Honours degree in Actuarial Science
                                – having completed a research paper titled “Funding options for a South African National
                                Health Insurance System.” While at Wits University, Sʼonqoba was invited to become a
                                lifetime member of the Golden Key International Honour Society thatre cognises the top
                                15% of academic achievers in universities in South Africa and the rest of the world.
                            </p>

                            <p>
                                Sʼonqoba served as the founding Operations Director of a Non-Profit Organisation
                                (NPO) that focuses on Youth Development in the Soweto and Alexandra areas. She also
                                worked as the Chief Operations Officer of a black-owned start-up Investment Holdings
                                company called Lante Investment Holdings; co-ordinating the operations and strategic
                                direction of the company, while also being the editor of the companyʼs inaugural
                                magazine. Sʼonqoba was an independent member of the South African Market Research
                                Association until October 2015. She is also a World Economic Forum Global Shaper
                                since 2014, servedas the Johannesburg Hub Deputy Curatorin 2015/16 and takes over
                                as the Hub Curatorin July.
                            </p>
                        </div>
                    </div>
                    <div className="founder-section-right-2"></div>
                </div>
            </TabPane>

            <TabPane tab={<div className='tab-head'> <span className="tab-head-name">Melvyn Lubega</span> <div className="diamond"></div></div>} key="3">
                <div className="founder-section-flex">
                    <div className="founder-info-container">
                        <div className="founder-info-text">
                            <div style={{ width: "100%" }} className={"partner-section-header"}>Melvyn Lubega</div>
                            <p>
                                Is an experienced technology entrepreneur who, through a relentless focus on leveraging
                                data to enable customer success, has built businesses that serve customers on every
                                continent, except Antarctica. He is also a referenced thought leader and invited speaker
                                both abroad and locally on digital transformation, disruptive innovation, as well as business
                                strategy. Last decade, he has been named by Forbes in their 30 Under 30 list.
                        </p>

                            <p>
                                An actuary and data scientist by training, he holds an undergraduate degree from the
                                University of Cape Town and post graduate degrees from the University of Oxford as a
                                Rhodes Scholar. At Oxford, Melvyn completed a double masters (MPP and MSc), receiving
                                a distinction for his research on the role of emerging technologies in organisational
                                transformation and human capital development.
                        </p>
                        </div>
                    </div>
                    <div className="founder-section-right-3"></div>
                </div>
            </TabPane>
        </Tabs>
    );

    return (
        <div className='landing-root'>
            {isTablet ?
                <div className="landing-toolbar">
                    <div className='landing-logo'>
                        <img width={50} src={logo_icon} />
                    </div>
                    <button onClick={() => setIsDrawerVisible(true)} className="landing-btn"><MenuOutlined /> </button>
                </div>
                :

                <div className='landing-toolbar'>
                    <div className='landing-logo'>
                        <img width={180} src={full_logo} />
                    </div>

                    <div className="landing-menu">
                        <Link activeClass="active" className="landing-menu-item" to="what-we-do" spy={true} smooth={true} offset={50} duration={500}>
                            What We Do
                     </Link>
                        <Link activeClass="active" className="landing-menu-item" to="why-we-do" spy={true} smooth={true} offset={50} duration={500}>
                            Why We Do It
                    </Link>
                        <Link activeClass="active" className="landing-menu-item" to="our-pillar" spy={true} smooth={true} offset={50} duration={500}>
                            Our Pillars
                    </Link>
                        <Link activeClass="active" className="landing-menu-item" to="value-prop" spy={true} smooth={true} offset={50} duration={500}>
                            Value Proposition
                    </Link>
                        <Link activeClass="active" className="landing-menu-item" to="partnership" spy={true} smooth={true} offset={50} duration={500}>
                            Partnerships
                    </Link>
                        <Link activeClass="active" className="landing-menu-item" to="team" spy={true} smooth={true} offset={50} duration={500}>
                            Join our team
                    </Link>
                        <Link activeClass="active" className="landing-menu-item" to="founders" spy={true} smooth={true} offset={50} duration={500}>
                            Founding Team
                    </Link>
                        <Link activeClass="active-partner" className="landing-menu-item partner-item" to="partner" spy={true} smooth={true} offset={50} duration={500}>
                            Partner with us
                    </Link>
                    </div>
                </div>
            }

            <div className="landing-section-top">
                <div className="overlay"></div>
                <div className="landing-center-text">
                    Enabling learning institutions to realise their
                    full potential with the use of technology.
                    </div>
                <div className="landing-read-more">Read more about us</div>
                <Link activeClass="active" to="what-we-do" spy={true} smooth={true} offset={50} duration={500}>
                    <img src={down_arrow_icon} className="landing-read-more-circle" />
                </Link>
            </div>

            <div className="landing_section_what_we_do" name="what-we-do">
                <div className="landing-section-header">What we do</div>
                <div className="landing-section-body">
                    <p>
                        We partner with forward thinking learning institutions and enable them to realise their
                        full potential with the use of technology.
                        </p>
                    <p>
                        Are you looking for ways to fully utilize your human resources, intellectual and
                        physical property, we have built proven business models and user friendly
                        technology that enables you to substantially increase your revenue within a short
                        period of time without compromising on the quality of education.
                        </p>
                </div>
            </div>

            <div className="landing_section_why_we_do" name="why-we-do">
                <div className="landing-section-header" style={{ marginTop: '60px' }} >Why we do it</div>
                <div className="why_we_do_section_body wide">
                    <p>
                        According to UNESCOʼs 2020 Global Education Monitoring Report, many of the worldʼs most vulnerable and disadvantaged face significant
                        obstacles that prevent them from accessing education. 17% (258 million) ofthe worldʼs children, adolescents andyouth are not in school. In
                        sub-Saharan Africa, itʼs 31% of young people.
                        </p>
                    <p>
                        Rethinking the future of education is now more important following the COVID-19 pandemic, which further widened and put a spotlight on
                        inequalities and failure to act will hinder the progress of societies.
                    </p>
                    {isTablet ?
                        <div className="graphs-col">
                            <img className="graph" src={graph_one} />
                            <img className="graph" src={graph_two} />
                        </div>
                        :
                        <img className="graph" src={graph_desktop} />
                    }

                    <p>
                        Africaʼs current learning institution physical capacity is stretched. In fact, we would have to build schools at an exponential rate to cater for
                        the youth boom which we believe is the key to unlocking Africaʼs potential. We believe technology has a significant role to play in redesigning
                        our education system for success and we are Africaʼs first education services company fully focused on enabling learning institutions to
                        utilize their resources in a profitable manner that allows us to meet the needs of the future.
                    </p>
                </div>
            </div>


            <div className="landing-section-white" name="our-pillar">
                <div className="landing-section-header" >Our pillars</div>
                <div className="landing-section-body">
                    <p>
                        Our business model is built based on the sound values and principles of sustainability, digital transformation, equality, accessibility and excellence
                        </p>
                    <p>
                        We build on these values to be more action oriented and results driven thus delivering agreed results on time,
                        to cost, and by keeping stakeholders informed about the processes and issues.
                        </p>
                </div>
                {isMobile ?
                    <img className="pillars-circles" src={pillars_icon_mobile} />
                    :
                    <img className="pillars-circles" src={pillars_icon} />
                }

            </div>

            {isTablet ?
                <div className="value-prop-mobile" name="value-prop">
                    <div className="val-prop-header" >Value proposition</div>
                    <div className="rev-calc-text">
                        We bridge the digital transformation gap for
                        learning institutions that deliver on 21st
                        century learning and teaching.
                    </div>
                    <Collapse accordion>
                        <Panel header="Hybrid Rotation Schooling Model" key="1">
                            <div className="val-prop-mobile">
                                <b className="prop-head">Hybrid Rotation Schooling Model</b>
                                <p>
                                    This model works for schools looking to run afternoon/evening/weekend classes.
                                    We provide you with the necessary expertise to double your learner intake and
                                    increase your revenue without increasing your physical footprint.
                                </p>
                                <div className="graph-flex-mobile">
                                    <img className="mobile-graph" src={old_way_mobile} />

                                    <img className="mobile-graph" src={new_way_mobile} />
                                </div>
                            </div>
                        </Panel>
                        <Panel header="Fully Automated Online School" key="2">
                            <div className="val-prop-mobile">
                                <b className="prop-head">Fully Automated Online School</b>
                                <p>
                                    Struggling to accommodate all qualified learners applying to your learning institution? You should consider launching your
                                    online school. We have built a holistic platform to cater for your needs. We have also designed a playbook to guide you
                                    through the decision making and preparation process. Our solution covers the following areas :
                                </p>
                                <ul>
                                    <li>Marketing and Outreach</li>
                                    <li>Application and Admissions lifecycle.</li>
                                    <li>Learning Management System (content hosting, project based learning, Assessment, personalized Feedback, grading and reporting, analytics</li>
                                    <li>Tutoring Service (Additional revenue source)</li>
                                    <li>Payment Collection</li>
                                    <li>Community Engagement</li>
                                    <li>Exams Administration</li>
                                    <li>Calendar and Scheduling</li>
                                    <li>Teacher Monitoring and Evaluation</li>
                                </ul>
                            </div>
                        </Panel>
                        <Panel header="Fully Automated Online School" key="3">
                            <div className="val-prop-mobile">
                                <b className="prop-head">General School LMS</b>
                                <p>
                                    We know you have great teachers but can you maintain your reputation if they leave?
                                    Our LMS solution enables you to store the content developed, learning templates and lessons learned.
                                    LMSs have proven to improve learner performance by allowing learners to revise content in a
                                    personalized manner and also enable teachers to be more effective with their time.
                                </p>
                            </div>
                        </Panel>
                    </Collapse>
                </div>
                :
                <div className="landing-section-value-prop" name="value-prop">
                    <div className="val-prop-header" >Value proposition</div>
                    <div className="landing-section-row">
                        <div className="rev-calculator">
                            <div className="rev-calculator-inner">
                                <div className="rev-calc-text">
                                    We bridge the digital transformation gap for
                                    learning institutions that deliver on 21st
                                    century learning and teaching.
                                </div>
                                <div onClick={() => toggleSelectedItem(1)} className={"rev-item " + (activeItem == 1 ? "selected" : "")}>1. Hybrid Rotation Schooling Model</div>
                                <div onClick={() => toggleSelectedItem(2)} className={"rev-item " + (activeItem == 2 ? "selected" : "")}>2. Fully Automated Online School</div>
                                <div onClick={() => toggleSelectedItem(3)} className={"rev-item " + (activeItem == 3 ? "selected" : "")}>3. General School LMS</div>
                                <p>
                                    See how much revenue can be generated for your Institution
                            </p>
                                <button onClick={() => toggleSelectedItem(4)} className="calc-rev-btn">
                                    <CalculatorOutlined className="calculator-icon" />  Calculate Revenue
                                </button>
                            </div>
                        </div>
                        {activeItem === 2 &&
                            <div className="val-prop">
                                <b className="prop-head">Fully Automated Online School</b>
                                <p>
                                    Struggling to accommodate all qualified learners applying to your learning institution? You should consider launching your
                                    online school. We have built a holistic platform to cater for your needs. We have also designed a playbook to guide you
                                    through the decision making and preparation process. Our solution covers the following areas :
                                </p>
                                <ul>
                                    <li>Marketing and Outreach</li>
                                    <li>Application and Admissions lifecycle.</li>
                                    <li>Learning Management System (content hosting, project based learning, Assessment, personalized Feedback, grading and reporting, analytics</li>
                                    <li>Tutoring Service (Additional revenue source)</li>
                                    <li>Payment Collection</li>
                                    <li>Community Engagement</li>
                                    <li>Exams Administration</li>
                                    <li>Calendar and Scheduling</li>
                                    <li>Teacher Monitoring and Evaluation</li>
                                </ul>
                            </div>
                        }
                        {activeItem === 1 &&
                            <div className="val-prop">
                                <b className="prop-head">Hybrid Rotation Schooling Model</b>
                                <p>
                                    This model works for schools looking to run afternoon/evening/weekend classes.
                                    We provide you with the necessary expertise to double your learner intake and
                                    increase your revenue without increasing your physical footprint.
                                </p>
                                <div className="graph-flex">
                                    <div style={{ display: 'flex', flexDirection: "column", textAlign: 'center' }}>
                                        Old Way
                                    <img className="graphs-prop old-way-pic" src={old_way} />
                                    </div>
                                    <div style={{ display: 'flex', flexDirection: "column", textAlign: 'center', color: "#242F40" }}>
                                        New Way
                                    <img className="graphs-prop new-way-pic" src={new_way} />
                                    </div>
                                </div>
                            </div>
                        }
                        {activeItem === 3 &&
                            <div className="val-prop">
                                <b className="prop-head">General School LMS</b>
                                <p>
                                    We know you have great teachers but can you maintain your reputation if they leave?
                                    Our LMS solution enables you to store the content developed, learning templates and lessons learned.
                                    LMSs have proven to improve learner performance by allowing learners to revise content in a
                                    personalized manner and also enable teachers to be more effective with their time.
                                </p>
                            </div>
                        }
                        {activeItem === 4 &&
                            <div className="val-prop">
                                <b className="prop-head">Calculate Revenue</b>

                                <p>See how much revenue can be generated for your Institution.</p>
                                <div className="calc-container">
                                    <Select onChange={(val) => setModel(val)} defaultValue="Model" className="calc-model">
                                        <Option value="hybrid">Hybrid Model</Option>
                                        <Option value="automated">Fully Automated Model</Option>
                                    </Select>

                                    <div className="slider-contain">
                                        Current No. of students
                                        <Slider min={0} max={5000} step={100} onChange={(n) => setNumOfStudents(n)} value={numOfStudents} />
                                    </div>

                                    <Select onChange={(val) => setPerformance(val)} defaultValue={0} placeholder={"Academic Performance"} className="calc-model">
                                        <Option value={1.2}>85% - 100% Average pass rate</Option>
                                        <Option value={0.8}>70% - 85% Average pass rate</Option>
                                        <Option value={0.6}>60% - 70% Average pass rate</Option>
                                    </Select>

                                    <div className="slider-contain">
                                        Current monthly fees
                                        <Slider onChange={(n) => setMonthlyFees(n)} min={0} max={10000} step={100} value={monthlyFees} />
                                    </div>

                                    <Select onChange={(val) => setPeriod(val)} defaultValue="Revenue Period" style={{ marginBottom: "20px" }} className="calc-model">
                                        <Option value="1">One Year</Option>
                                        <Option value="3">Three Years</Option>
                                        <Option value="5">Five Years</Option>
                                    </Select>

                                    <Select onChange={(val) => setInstitution(val)} defaultValue="Institution Type" className="calc-model">
                                        <Option value="higher">Higher Institution</Option>
                                        <Option value="secondary">Secondary Institution</Option>
                                    </Select>

                                    <div style={{ fontSize: '0.8rem', color: '#363636', marginTop: '15px' }}>This is how much revenue that can potentially be generated for your Institution.</div>

                                    <div className="res-section">
                                        <div className="res-div">
                                            <span className='result-text'>Result: <br /></span>
                                            <span className='result-amt'>R{revenue.toLocaleString() + " - R" + (revenue * 1.5).toLocaleString()}</span>
                                        </div>
                                        <span onClick={reset} className='reset-class' >Reset</span>
                                    </div>
                                </div>
                            </div>
                        }
                    </div>
                </div>
            }

            <div className="landing-section-split" style={{ minHeight: '60vh' }}>
                <div className="left" >
                    <p>
                        We are deeply committed to building systems and software
                        that will enable our partners and stakeholders to have
                        national footprint and the capacity to scale continentally in a
                        sustainable manner.
                    </p>
                    <p>
                        We work with you to understand your digital strategic goals,
                        pain points, culture, technological and stakeholder
                        involvement (employee,learners and parents) needs and
                        your preferences and then customize our digital solutions
                        and approach to fit your needs
                    </p>
                </div>
                <div className="right">

                </div>
            </div>

            <div className='section-risk' name="our-pillar">
                <div className="landing-center-text-smaller" style={{ color: "#fff" }}>All school partnerships are driven by a shared risk model.</div>
            </div>
            <div name="partnership" className="landing-section-partner">
                <div className="partner-flex">
                    <div className="partner-left"></div>

                    <div className="partner-right">
                        <div>
                            <div className="partner-section-header">
                                Partnerships and Investments
                            </div>

                            <div className="partner-section-body">
                                <p>
                                    All school partnerships are driven by a shared risk model. We strongly believe this is the right incentive for all
                                    stakeholders to create the best solution. Due to this nature, we have a highly rigorous selection process that
                                    focuses on 4 Midas pillars:
                                </p>
                            </div>
                        </div>

                        <div className="pillar-flex">
                            <div className="pillar-card">
                                <img className="pillar-icon" src={lion_icon} />
                                <div className="pillar-heading">Brand Strength </div>
                                <div className="pillar-body">
                                    How well positioned is your brand to successfully attract the right candidates
                                </div>
                                <div style={{ textAlign: 'right', fontSize: '2rem' }}>
                                    <img className="tick-icon" src={tick_icon} />
                                </div>
                            </div>

                            <div className="pillar-card">
                                <img className="pillar-icon owl-icon" src={owl_icon} />
                                <div className="pillar-heading">Academic Track Record</div>
                                <div className="pillar-body">
                                    We look at your graduate placement / performance benchmark over the last 3 - 5 years
                                </div>
                                <div style={{ textAlign: 'right', fontSize: '2rem' }}>
                                    <img className="tick-icon" src={tick_icon} />
                                </div>

                            </div>

                            <div className="pillar-card">
                                <img className="pillar-icon" src={ai_icon} />
                                <div className="pillar-heading">Digital Readiness</div>
                                <div className="pillar-body">
                                    How flexible are you with learning new technology and getting comfortable in unknown territory
                                </div>
                                <div style={{ textAlign: 'right', fontSize: '2rem' }}>
                                    <img className="tick-icon" src={tick_icon} />
                                </div>
                            </div>

                            <div className="pillar-card">
                                <img className="pillar-icon" src={ai_icon2} />
                                <div className="pillar-heading">Technical Literacy</div>
                                <div className="pillar-body">
                                    How much of edtech innovation in the 21st century have you adopted in your learning environment
                                </div>
                                <div style={{ textAlign: 'right', fontSize: '2rem' }}>
                                    <img className="tick-icon" src={tick_icon} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="landing-section-white" name="our-pillar" style={{ height: "60vh" }}>
                <div className="section-header-our-patners">Our Partners</div>

                <div className="landing-section-partners">
                    <img width={220} height={53} src={ALA_logo} />
                    <img width={257} height={98} src={Nova_logo} />
                    <img width={232} height={59} src={UJ_logo} />
                    <img width={221} height={47} src={Microsoft_logo} />
                </div>
                <hr className='line' ></hr>

                <div className="landing-section-body">
                    We've raised a dedicated fund which can be used to enable deserving institutions at the beginning of
                    their digital transformation journey. Each institution qualifies for a maximum of R600k in upfront costs which is
                    recouped at an agreed milestone post revenue.
                </div>
            </div>

            <div name="partner" className="form-section">
                <div className="form-section-overlay"></div>
                <div>

                </div>

                <div className="landing-form-container">
                    <div className="landing-form-header">
                        Application Form
                        <hr className="form-header-line" />
                    </div>
                    <div className='landing-form-inner'>
                        <input className="form-input" placeholder="Email Address" />
                        <input className="form-input" placeholder="Full Name" />
                        <input className="form-input" placeholder="Contact Number" />
                        <input className="form-input" placeholder="Institution Name" />
                        <input className="form-input" placeholder="Province" />
                        <input className="form-input" placeholder="What service are you interested in ?" />
                        <input className="form-input" placeholder="Any information you would want to share?" />
                        <button className="send-btn">
                            Send
                        </button>
                    </div>
                </div>
            </div>

            <div className="landing-section-advisors">
                <div className="advisor-section-header">Join Our Team</div>
                <div className="landing-section-body" style={{ marginBottom: '0px' }}>
                    We are always looking for talented individuals to join our team and work with us to bring this vision to life. If you
                    are a rockstar and would like to do some incredible work with our team. Please send us an email at
                    <b> rockstar@digiclass.co.za</b>
                </div>
                <div className="advisor-section-header">Advisors</div>
                <div name="team" className="advisor-list">
                    <div className="advisor-card">
                        <img className="advisor-image" src={fred} />
                        <div className='advisor-name'>
                            Fred Swaniker
                        </div>
                        <div className='advisor-desc'>
                            Stanford MBA/Mckinsey Alumni
                            Founder and CEO
                            African Leadership University
                        </div>
                    </div>
                    <div className="advisor-card">
                        < img className="advisor-image" src={ratshefola} />
                        <div className='advisor-name'>
                            Victoria Ratshefola CA (SA)
                        </div>
                        <div className='advisor-desc'>
                            Head of Credit FNB South Africa
                        </div>
                    </div>
                    <div className="advisor-card">
                        <img className="advisor-image" src={chris} />
                        <div className='advisor-name'>
                            Chris Bradford
                        </div>
                        <div className='advisor-desc'>
                            Standford MBA/BCG Alumni
                            Founder and CEO
                            African Leadership Academy
                        </div>
                    </div>
                    <div className="advisor-card">
                        <img className="advisor-image" src={alim} />
                        <div className='advisor-name'>
                            Alim Ladha
                    </div>
                        <div className='advisor-desc'>
                            INSEAD/Mckinsey Alumni
                            Founder and CEO
                            Instill Education
                        </div>
                    </div>
                    <div className="advisor-card">
                        <img className="advisor-image" src={samone} />
                        <div className='advisor-name'>
                            Samone Biaou
                        </div>
                        <div className='advisor-desc'>
                            Stanford MBA/BCG Alumni
                            Advisor, Qatar ministry of education
                            Edtech Expert
                        </div>
                    </div>
                </div>

                <div className="advisor-section-header">The Founding Team</div>
                <FounderTabs />
            </div>
            <div className="footer">

                <div className="footer-menu">
                    <span><img width={70} src={logo_icon} /></span>
                    <div className="footer-menu-item" style={{ marginLeft: "auto" }}>
                        <p>
                            © 2020 Digiclass. All Rights Reserved.
                        </p>
                        <p>
                            Strouthos Place | Hyde Park | 2194<br />
                            The Offices of Hyde Park<br />
                            Ground Floor | Block B
                        </p>
                    </div>

                </div>
            </div>
            <Drawer
                title="Menu"
                placement={"right"}
                width={"90%"}
                className={"mobile-menu"}
                closable={true}
                onClose={onDrawerClose}
                visible={drawerVisible}
            >
                <div className="mobile-menu-container" >
                    <Link activeClass="mobile-active" onClick={() => setIsDrawerVisible(false)} className="mobile-menu-item" to="what-we-do" spy={true} smooth={true} offset={50} duration={500}>
                        What We Do
                     </Link>
                    <Link activeClass="mobile-active" onClick={() => setIsDrawerVisible(false)} className="mobile-menu-item" to="why-we-do" spy={true} smooth={true} offset={50} duration={500}>
                        Why We Do It
                    </Link>
                    <Link activeClass="mobile-active" onClick={() => setIsDrawerVisible(false)} className="mobile-menu-item" to="our-pillar" spy={true} smooth={true} offset={50} duration={500}>
                        Our Pillars
                    </Link>
                    <Link activeClass="mobile-active" onClick={() => setIsDrawerVisible(false)} className="mobile-menu-item" to="value-prop" spy={true} smooth={true} offset={50} duration={500}>
                        Value Proposition
                    </Link>
                    <Link activeClass="mobile-active" onClick={() => setIsDrawerVisible(false)} className="mobile-menu-item" to="partnership" spy={true} smooth={true} offset={50} duration={500}>
                        Partnerships
                    </Link>
                    <Link activeClass="mobile-active" onClick={() => setIsDrawerVisible(false)} className="mobile-menu-item" to="team" spy={true} smooth={true} offset={50} duration={500}>
                        Join our team
                    </Link>
                    <Link activeClass="mobile-active" onClick={() => setIsDrawerVisible(false)} className="mobile-menu-item" to="founders" spy={true} smooth={true} offset={50} duration={500}>
                        Founding Team
                    </Link>
                    <Link activeClass="active-partner" onClick={() => setIsDrawerVisible(false)} className="mobile-menu-item mobile-partner-item" to="partner" spy={true} smooth={true} offset={50} duration={500}>
                        Partner with us
                    </Link>

                </div>

            </Drawer>
        </div>
    )
}
